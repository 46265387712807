<template>
  <div>
    <b-row>
      <b-col cols="4"></b-col>
      <b-col cols="6"></b-col>
      <b-form-checkbox v-model="checked" name="check-button" switch>
        <b>Switch For Excel</b>
      </b-form-checkbox>
    </b-row>
    <validation-observer ref="availableRoomsValidation">
      <b-form @submit.prevent v-if="!checked">
        <b-row v-if="getLoggedInUser.role_data.code_name === 'su' || getLoggedInUser.role_data.code_name === 'bk__agm'">
          <b-col cols="4" class="ml-3">
            <validation-provider #default="{ errors }" name="City">
              <b-form-group label-for="city" :state="errors.length > 0 ? false : null">
                <template #label>
                  City <span class="text-danger">*</span>
                </template>
                <b-row>
                  <b-col>
                    <v-select id="city" inputId="id" label="name" v-model="selectedCity" :options="cities"
                      placeholder="City" class="v-style-chooser" />
                  </b-col>
                </b-row>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="4" v-if="selectedCity">
            <b-form-group label-for="mess">
              <template #label>
                Mess <span class="text-danger">*</span>
              </template>
              <b-row>
                <b-col>
                  <v-select id="mess" inputId="id" label="name" v-model="selectedMess" :options="messes"
                    placeholder="Mess" class="v-style-chooser" />
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>

          <b-col cols="3">
            <div v-if="selectedMess" class="mt-2">
              <b-button variant="info" pill @click="viewAvailableRooms">
                <feather-icon icon="PlusIcon" class="mr-50" />
                <span class="align-middle">View Available Rooms</span>
              </b-button>
            </div>
          </b-col>

        </b-row>
        <b-row v-else>
          <b-col md="4"></b-col>
          <b-col md="4">
            <b-button variant="info" pill @click="viewAvailableRooms" class="ml-5 mb-1 mt-1">
              <feather-icon icon="PlusIcon" class="mr-50" />
              <span class="align-middle">View Available Rooms</span>
            </b-button>
          </b-col>
          <b-col md="4"></b-col>
        </b-row>

      </b-form>
      <b-form v-else>
        <b-row v-if="getLoggedInUser.role_data.code_name === 'su' || getLoggedInUser.role_data.code_name === 'bk__agm'">
          <b-col cols="4" class="ml-3">
            <validation-provider #default="{ errors }" name="City">
              <b-form-group label-for="city" :state="errors.length > 0 ? false : null">
                <template #label>
                  City <span class="text-danger">*</span>
                </template>
                <b-row>
                  <b-col>
                    <v-select id="city" inputId="id" label="name" v-model="selectedCity" :options="cities"
                      placeholder="City" class="v-style-chooser" />
                  </b-col>
                </b-row>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="4" v-if="selectedCity">
            <b-form-group label-for="mess">
              <template #label>
                Mess <span class="text-danger">*</span>
              </template>
              <b-row>
                <b-col>
                  <v-select id="mess" inputId="id" label="name" v-model="selectedMess" :options="messes"
                    placeholder="Mess" class="v-style-chooser" />
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>

          <b-col cols="3">
            <div v-if="selectedMess" class="mt-2">
              <b-button variant="primary" pill @click="downloadAailableRoomsExcel">
                <feather-icon icon="PlusIcon" class="mr-50" />
                <span class="align-middle">Download Available Rooms Excel</span>
              </b-button>
            </div>
          </b-col>

        </b-row>
        <b-row v-else>
          <b-col md="4"></b-col>
          <b-col md="4">
            <b-button v-if="checked" variant="primary" pill @click="downloadAailableRoomsExcel" class="ml-5 mb-1 mt-1">
              <feather-icon icon="PlusIcon" class="mr-50" />
              <span class="align-middle">Download Available Rooms Excel</span>
            </b-button>
          </b-col>
          <b-col md="4"></b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <div class="legend  ml-3 mb-2 mb-2">
      <div class="legend-item booked">
        <span class="legend-color table-danger" ></span>
        Booked
      </div>
      <div class="legend-item temporarily-unavailable">
        <span class="legend-color table-info"></span>
        Temporarily-Unavailable <div class="ml-1">(T-Unavailable)</div>
      </div>
      <div class="legend-item permanently-unavailable">
        <span class="legend-color table-primary"></span>
        Permanently-Unavailable <div class="ml-1">(P-Unavailable)</div>
      </div>
      <div class="legend-item available">
        <span class="legend-color table-warning"></span>
        Available
      </div>
    </div>

    <b-card>
      <b-table responsive :fields="fields" :items="roomAvailability" small bordered>
        <template #cell(RoomName)="row">
          {{ row.item["Room Name"] }}
        </template>
        <!-- <template v-for="date in dateRange" #[`cell(${date})`]="row">
          <span v-if="row.item[date] === 'Available'" :key="`${row.item.id}-${date}`" class="cell-available">{{
        row.item[date] }}</span>
          <span v-else-if="row.item[date] === 'Unavailable'" :key="`${row.item.id}-${date}`" class="cell-unavailable">{{
        row.item[date] }}</span>
          <span v-else :key="`${row.item.id}-${date}`" class="cell-booked">{{ row.item[date] }}</span>
        </template> -->
      </b-table>

      <b-row>
        <b-col md="2">
          <div style="float:left">
            <h5 style="margin: 0; display: inline;" class="text-primary">Count: </h5>
            <h5 style="margin: 0; display: inline;" align="right">
              <strong>
                {{ totalDataLength }}
              </strong>
            </h5>
          </div>
        </b-col>
      </b-row>

    </b-card>

    <b-overlay :show="show" spinner-variant="primary" no-wrap />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import util from "@/util.js";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import moment from "moment";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      fields: [],
      roomAvailability: [],
      dateRange: [],
      currentPage: 1,
      perPage: 0,
      totalItems: 0,
      room: null,
      totalDataLength: 0,
      messSecretary: false,
      messReceptionist: false,
      selectedMess: null,
      messes: [],
      selectedMessId: null,
      cities: [],
      selectedCity: null,
      show: false,
      exportXls: false,
      checked: false
    };
  },
  mixins: [util],
  async mounted() {
    if (this.getLoggedInUser.mess_secretary) {
      this.messSecretary = true
      this.selectedMessId = this.getLoggedInUser.mess_secretary.mess
    }
    else if (this.getLoggedInUser.clerk) {
      this.messReceptionist = true
      this.selectedMessId = this.getLoggedInUser.clerk.mess
    }
    let res = await this.getBookingCitiesUnpaginated({
      mess_only: true,
    });
    this.cities = res.data
    // await this.search();
  },
  methods: {
    ...mapActions({
      getMessesUnpaginated: "appData/getMessesUnpaginated",
      getRoomsAvailabilityReport: "appData/getRoomsAvailabilityReport",
      getBookingCitiesUnpaginated: "appData/getBookingCitiesUnpaginated",
      roomsAvailabilityReportFromExcel: "appData/roomsAvailabilityReportFromExcel"
    }),
    async updateMess() {
      try {
        this.selectedMess = null
        let res = await this.getMessesUnpaginated({ city: this.selectedCity.id })
        this.messes = res.data
      }
      catch (error) {
        this.displayError
      }
    },
    async viewAvailableRooms() {
      this.show = true;
      if (this.selectedMess) {
        this.selectedMessId = this.selectedMess.id;
      }
      let payload = {
        mess: this.selectedMessId
      };

      try {
        const res = await this.getRoomsAvailabilityReport({ payload });
        if (res.status === 200 && res.data.length > 0) {
          this.roomAvailability = res.data;
          this.totalDataLength = this.roomAvailability.length
          this.extractDateRange();

          this.$swal({
            title: "Data loaded successfully",
            icon: "success",
            timer: 1500,
          });
        } else {
          this.$swal({
            title: "No Record Found",
            icon: "info",
          });
        }
        this.show = false;
      } catch (error) {
        this.show = false;
        this.displayError(error);
      }
    },
    extractDateRange() {

      let dates = new Set();
      this.roomAvailability.forEach((room) => {
        room['_cellVariants'] = {}
        Object.keys(room).forEach((key) => {
          if (key !== "Room Name" && key !== '_cellVariants') {
            if(room[key] == 'Available'){
              room['_cellVariants'][key] = 'warning'
            }
            else if(room[key] == 'Booked'){
              room['_cellVariants'][key] = 'danger'
            }
            else if(room[key] == 'T-Unavailable'){
              room['_cellVariants'][key] = 'info'
            }
            else if(room[key] == 'P-Unavailable'){
              room['_cellVariants'][key] = 'primary'
            }
            dates.add(key);
          }
        });
      });
      this.dateRange = Array.from(dates).sort();
    },
    async downloadAailableRoomsExcel() {
      this.show = true;
      this.exportXls = true;
      if (this.selectedMess) {
        this.selectedMessId = this.selectedMess.id
        console.log(this.selectedMessId = this.selectedMess.id)
      }
      let payload = {
        mess: this.selectedMessId
      };
      try {
        const res = await this.roomsAvailabilityReportFromExcel({
          payload
        });
        if (res.status === 200) {
          var currentdate = new Date();
          const url = URL.createObjectURL(res.data);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            'Available Rooms In Mess Report ' + currentdate.getDate() + "-" + + (currentdate.getMonth() + 1) + "-" + currentdate.getFullYear() + " --- " + currentdate.getHours() + "-" + currentdate.getMinutes() + "-" + currentdate.getSeconds() + ".xlsx"
          );
          this.show = false;
          document.body.appendChild(link);
          link.click();
          this.$swal({
            title: "Download successful",
            icon: "success",
            timer: 1500,
          });
        }
        if (res.status === 204) {
          this.$swal({
            title: "No Record Found",
            icon: "info",
          });
        }
        this.show = false;
      } catch (error) {
        this.show = false;
        this.displayError(error);
      }
    },
  },
  computed: {
    ...mapGetters({
      hasPermission: "appData/hasPermission",
      getLoggedInUser: "appData/getUser",
    }),
  },
  watch: {
    async selectedCity(newValue, oldValue) {
      try {
        if (newValue) {
          this.messes = []
          await this.updateMess();
        } else {
          return
        }
      } catch (error) {
        console.error('Error in selectedMess watch:', error);
      }
    },
  },
};
</script>

<style scoped>
.cell-available {
  background-color: #FEBE10;
  color: transparent;
}

.cell-temporarily-unavailable {
  background-color: rgb(13, 63, 13);
  color: transparent;
}

.cell-permanently-unavailable {
  background-color: #17a2b8;
  color: transparent;
}

.cell-booked {
  background-color: #990000;
  color: transparent;
}

.legend-item {
  display: flex;
  align-items: center;
}

.legend-color {
  width: 15px;
  height: 15px;
  margin-right: 5px;
  border-radius: 50%;
}
</style>